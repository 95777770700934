<template>
<main>
  <q-payment />





  <!-- <b-row class="m-5">
    <b-col lg="8" sm="12" md="12" class="m-auto">




      <div class="row">
        <div class="col-lg-12 text-center">
          <h1 class="h1 font-weight-bold">RÉSUMÉ DE L'ABONNEMENT</h1>
        </div>
      </div>
      <b-card>
        <b-row class="mt-2">
          <b-col lg="12" class="m-auto">
            <b-row class="ml-5 mr-5">
              <b-col lg="6" class="m-auto text-center">
                <span style="font-size: 18px">Plan</span>
              </b-col>
              <b-col lg="6" class="m-auto text-center">
                <b-badge variant="light-jaune">
                  <span style="font-size: 20px">{{ plan }}</span>
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="ml-5 mr-5 mt-1">
              <b-col lg="6" class="m-auto text-center">
                <span style="font-size: 18px">Durée</span>
              </b-col>
              <b-col lg="6" class="m-auto text-center">
                <b-badge variant="light-success">
                  <span style="font-size: 20px">{{ duree }} Jours</span>
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="ml-5 mr-5 mt-1">
              <b-col lg="6" class="m-auto text-center">
                <span style="font-size: 18px">Total HT</span>
              </b-col>
              <b-col lg="6" class="m-auto text-center">
                <b-badge variant="light-warning">
                  <span style="font-size: 20px">{{ ht }} {{ devise }}</span>
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="ml-5 mr-5 mt-1">
              <b-col lg="6" class="m-auto text-center">
                <span style="font-size: 18px">TVA</span>
              </b-col>
              <b-col lg="6" class="m-auto text-center">
                <b-badge variant="light-warning">
                  <span style="font-size: 20px">{{ tva }} % </span>
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="ml-5 mr-5 mt-1">
              <b-col lg="6" class="m-auto text-center">
                <span style="font-size: 18px">Total TTC</span>
              </b-col>
              <b-col lg="6" class="m-auto text-center">
                <b-badge variant="light-success">
                  <span style="font-size: 20px">{{ ttc }} {{ devise }}</span>
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="ml-5 mr-5 mt-1">
              <b-col lg="6" class="m-auto text-center">
                <span style="font-size: 18px">Date de souscription</span>
              </b-col>
              <b-col lg="6" class="m-auto text-center">
                <b-badge variant="light-success">
                  <span style="font-size: 18px">{{ today }}</span>
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="ml-5 mr-5 mt-1">
              <b-col lg="6" class="m-auto text-center">
                <span style="font-size: 18px">Date de réabonnement</span>
              </b-col>
              <b-col lg="6" class="m-auto text-center">
                <b-badge variant="light-danger">
                  <span style="font-size: 18px">{{ dr }}</span>
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="ml-5 mr-5 mt-1">
              <b-col lg="12" class="m-auto text-center">
                <span style="font-size: 18px" class="text-success font-weight-bold"
                  >Les avantages</span
                >
              </b-col>
            </b-row>

            <b-row class="ml-5 mr-5 mt-1">
              <b-col lg="10" class="m-auto text-left">
                <b-card class="bg-light-success w-100">
                  <div class="row">
                    <div class="col-8 m-auto">
                      <span v-for="module in modules" :key="module">
                        <feather-icon icon="CheckCircleIcon" class="mr-1" />
                        {{ module }}<br />
                      </span>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>

            <b-row class="ml-5 mr-5 mt-1">
              <b-col lg="5" class="m-auto text-center d-flex">
                <b-button
                  disabled
                  variant="primary"
                  block
                  type="submit"
                  class="bg-indigo"
                  v-if="marche"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </b-button>
                <b-button
                  variant="primary"
                  @click="payWithPaydunya"
                  block
                  type="submit"
                  v-else
                  class="bg-indigo"
                >
                  Passer au paiement
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row> -->

   <b-row class="ml-5 mr-5 mt-1">
              <b-col lg="5" class="m-auto text-center d-flex">
                <b-button
                  disabled
                  variant="primary"
                  block
                  type="submit"
                  class="bg-indigo"
                  v-if="marche"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </b-button>
                <b-button
                  variant="primary"
                  @click="payWithPaydunya"
                  block
                  type="submit"
                  v-else
                  class="bg-indigo"
                >
                  Passer au paiement
                </b-button>
              </b-col>
            </b-row>
          </main>
</template>

<script src="https://paydunya.com/assets/psr/js/psr.paydunya.min.js"></script>
<script src="https://code.jquery.com/jquery.min.js"></script>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BFormInput,
  BCard,
  BImg,
  BCardText,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BForm,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import URL from "@/views/pages/request";
import axios from "axios";
import { qCrypt, qCryptJson, qDecryptedJson } from "@/services/qeCrypt";
import moment from "moment";
import QPayment from './qPayment.vue';
// import * from 'https://paydunya.com/assets/psr/js/psr.paydunya.min.js';
// import  * from 'https://code.jquery.com/jquery.min.js';
/* eslint-disable global-require */
export default {
  components: {
    BForm,
    BFormGroup,
    axios,
    BFormInput,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    QPayment,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modules: [
        "Création de Devis",
        "Création de Facture",
        "Gestion de catalogue",
        "Jusqu'à 15 utilisateurs en plus",
      ],
      getPlan: {},
      devise: "",
      today: "",
      reabonnement: "",
      selected: "",
      marche: "",
      plan: "",
      duree: "",
      ttc: "",
      tva: "",
      ht: "",
      ds: "",
      dr: "",
      ds_e: "",
      dr_e: "",
      rem: "",
      abonnement_id: "",
      montant_tva: "",

      methods: [
        {
          libelle: "Orange Money",
          image: "",
        },
        {
          libelle: "Moov Monney",
          image: "",
        },
        {
          libelle: "MTN MoMo",
          image: "",
        },
        { libelle: "PayPal", image: "" },
      ],
    };
  },
  async mounted() {
    document.title = "Paiement - Ediqia";

    const getPlan = localStorage.getItem('mvenos')
this.getPlan =  qDecryptedJson(getPlan)
// console.log(this.getPlan )

  
    
  },
  methods: {
    async payWithPaydunya(e) {
      e.preventDefault();
      const isPaymentTerminated = qDecryptedJson(localStorage.getItem('qhze_022'));
                  console.log(isPaymentTerminated)
      this.marche = true;
      let data = {
        montant_tva: (this.getPlan.prix_abn * 18) / 100,
        montant_ht: this.getPlan.prix_abn - ((this.getPlan.prix_abn * 18) / 100),
        montant_ttc: this.getPlan.prix_abn,
        duree: this.getPlan.duree,
        abonnement_id: this.getPlan.id,
        date_fin: this.getPlan.date
        
      };
      // ${isPaymentTerminated['entreprise'].id}/${isPaymentTerminated['status_paiement'].created_user}
      await axios.get(URL.PAIEMENT + `/${this.getPlan.id}`).then((response) => {
        this.marche = false;
          window.location.assign(response.request.responseURL);
        
      }).catch((error) =>{
        console.log(error)
      })
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
@import "https://paydunya.com/assets/psr/css/psr.paydunya.min.css";
[dir] .pricing-card .card.popular {
  border: 1px solid #450077;
}
</style>
