<template>
   <!-- Payments -->
   <section class="flex flex-col gap-16 py-16">
      <!-- Header -->
      <div class="flex flex-col text-center w-10/12 sm:w-8/12 m-auto justify-center">
         <span class="text-3xl font-bold text-purple-900">
            Information de paiments</span
         >

         <!-- Header sub -->
         <div class="w-10/12 sm:w-7/12 m-auto text-center">
            <span class="text-lg"
               >toutes les transcations sont sûres et sécurisées. nous ne
               sauvegardons jamais les détails de votre carte ou numéro.</span
            >
         </div>
      </div>

      <!-- Summary, detail user   -->
      <div class="bg-white py-8">
         <div class="w-10/12 sm:w-6/12 m-auto grid grid grid-cols-1 sm:grid-cols-2 gap-y-16 sm:gap-y-0">
            <!--  -->
            <div class="flex flex-col border-b-[1px] sm:border-r-[1px] sm:border-b-0  ">
               
               <div class="">
                  
                  <div class="mt-1 flex flex-col gap-y-2">
                     <div v-for="(paie, index) in paySummary" :key="index">
                        <div class="flex flex-col">
                           <span class="text-md text-gray-400 capitalize">
                              {{ paie.title }}
                           </span>
                           <span class="text-2xl font-semibold">
                              {{ paie.content }}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <!--  -->
            <div class="flex flex-col  sm:m-auto">
               <div class="">
                  <span class="text-yellow-300 text-3xl">Premium</span>
               </div>
               <span class="text-gray-400 uppercase text-sm">Montant TTC</span>
               <div class="font-extrabold text-2xl">
                  <span> {{prix }} Fcfa </span>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/composition-api';
import { qDecryptedJson } from '@/services/qeCrypt';
export default {
   setup() {
      // @Data to initial
      const paySummaryPacks = reactive([
         {
            name: 'Gratuit',
            isTrue: false,
         },
         {
            name: 'Payments',
            isTrue: false,
         },
      ]);

      const prix =ref(0)

      const paySummary = ref([]);

      onMounted(() => {
         const getPlans = localStorage.getItem('mvenos');
         
          const plan = qDecryptedJson(getPlans);
          prix.value = plan.prix
         console.log(paySummary)
         paySummary.value = [
            {
               title: 'Montant HT',
               content: parseInt(plan.prix ) - ( parseInt(plan.prix ) * 18) / 100 + " FCFA",
            },
            {
               title: 'TVA',
               content: "18%",
            },
            {
               title: 'Periode',
               content: plan.title,
            },
            {
               title: 'Renouvellement',
               content: plan.date,
            },
         ];
      });

      return {
         paySummary,
         paySummaryPacks,
         prix
      };
   },
};
</script>

<style></style>
